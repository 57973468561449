<template>
<div>
    <div class="card">
        <div class="card-header bg-dark text-white"><i class="fa fa-flask"></i> <b>{{ this.filename }}</b></div>
        <div class="card-body">
            <pre>{{ logs }}</pre>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        mounted() {
            this.$http.get('log').then((response) => {
                this.filename = response.data.filename;
                this.logs = response.data.logs;
            });
        },
        data() {
            return {
                filename: undefined,
                logs: undefined
            }
        }
    }
</script>
